import styles from "./Layout.module.css";


export function AuthPageLayout({ children }) {
  return (
      <div className={styles.layout}>
        <main className={styles.main}>
          {children}
        </main>
      </div>
  );
}

export default AuthPageLayout;
