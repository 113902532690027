import { useEffect, useState } from "react";

export function useLoginUrl() {
  const [origin, setOrigin] = useState("https://www.scientificamerican.com");
  useEffect(() => {
    setOrigin(window.location.origin);
  }, []);

  const authPortalHost = `https://${import.meta.env.PUBLIC_AUTH0_DOMAIN}`;
  const fallbackAuthorizeUrl = `${authPortalHost}/authorize?client_id=${import.meta.env.PUBLIC_AUTH0_CLIENT_ID}&response_type=code&redirect_uri=${encodeURIComponent(origin)}/callback/`;
  return fallbackAuthorizeUrl;
}

export default useLoginUrl;
