import { AuthPageContent, AuthPageLayout } from "~features/auth/components";

/**
 * Auth Page
 * @param {{
 *  action: "login"|"register"|"logout"|"callback"
 *  returnTo?: {
 *    url: string,
 *    path: string
 *  }
 * }} params
 */
export default function AuthPage({ action, returnTo }) {
  return (
    <AuthPageLayout>
      <AuthPageContent action={action} returnTo={returnTo} />
    </AuthPageLayout>
  );
}
