import { useEffect, useState } from "react";

import Cookies from "js-cookie";
import { cx } from "~utils";

import { useAuth } from "~/features/auth";
import { useEnvironment } from "~core/hooks/use-environment";
import { usePage } from "~core/hooks/use-page";
import { usePageQuery } from "~core/hooks/use-page-query";
import { useAuth0CallbackParams } from "~features/auth/provider";
import { useSciAmJwtSync } from "~features/piano/hooks/use-piano-auth-sync";
import { AUTH0_ERROR_STATES } from "../constants";
import { useLoginUrl } from "../hooks/use-login-url";
import { Loader } from "./Loader";

import styles from "./Content.module.css";

/**
 * @param {{ action: "login"|"register"|"logout"|"callback" }} params
 */
export function AuthPageContent({ action }) {
  const actionMap = {
    login: <Login />,
    register: <Register />,
    logout: <Logout />,
    callback: <Callback />,
  };

  return actionMap[action] || <Login />;
}

const BaseContent = ({ className, children }) => {
  return <div className={cx(styles.content, className)}>{children}</div>;
};

function Login() {
  const { pageData } = usePage();
  const returnTo = pageData?.props?.returnTo;

  const { auth } = useEnvironment();
  const { isLoggedIn, isLoading, login } = useAuth();
  const fallbackAuthorizeUrl = useLoginUrl();

  // 2.5s delay before redirecting so the user perceives what's happening
  const [timer, setTimer] = useState(null);
  useEffect(() => {
    clearTimeout(timer);
    if (isLoading) return;
    setTimer(
      setTimeout(() => {
        // When the user is already logged in, calling login() is a no-op.
        // Let's redirect them back to the site instead.
        if (isLoggedIn) {
          window.location.href = returnTo?.path || "/";
        } else {
          login(returnTo?.path || "/");
        }
      }, 2500),
    );
  }, [auth.provider, isLoggedIn, isLoading]);

  return (
    <BaseContent className={styles.login}>
      <Loader />
      <h1>{isLoggedIn ? "Already logged in" : "Opening login page..."}</h1>
      <p>
        If you are not redirected automatically,{" "}
        <a
          // This fallback Auth0 URL is used in case JavaScript or React breaks
          // - ssr/pre-hydration - redirects back to the prod homepage
          // - post-hydration - redirects to the current tab's homepage
          href={fallbackAuthorizeUrl}
          onClick={(e) => {
            e.preventDefault();
            login(returnTo?.path || "/");
          }}
        >
          click here
        </a>
        .
      </p>
    </BaseContent>
  );
}

function Register() {
  const { pageData } = usePage();
  const returnTo = pageData?.props?.returnTo;

  const { auth } = useEnvironment();
  const { isLoading, isLoggedIn, register } = useAuth();
  const fallbackAuthorizeUrl = useLoginUrl();

  // Redirect to registration page after 2.5s
  // If the user is logged in, do nothing
  useEffect(() => {
    if (isLoggedIn || isLoading) return;
    register(returnTo?.path || "/");
  }, [isLoading, isLoggedIn, auth.provider]);

  return (
    <BaseContent className={styles.register}>
      {isLoggedIn ? (
        <>
          <h1>Already Have an Account</h1>
          <p>
            You are already created your account and signed in. If you would like to create a new
            account, please <a href="/logout/?returnTo=/register/">log out</a> first.
          </p>
        </>
      ) : (
        <>
          <Loader />
          <h1>Create Your Account</h1>
          <p>
            If you are not redirected automatically,{" "}
            <a
              // Same as the fallback in the <Login> component but with a different screen_hint
              href={fallbackAuthorizeUrl + "&screen_hint=signup"}
              onClick={(e) => {
                e.preventDefault();
                register(returnTo?.path || "/");
              }}
            >
              click here
            </a>
            .
          </p>
        </>
      )}
    </BaseContent>
  );
}

function Logout() {
  const { isLoggedIn, logout, isLoading } = useAuth();
  const { auth } = useEnvironment();
  const { pageData } = usePage();
  const { clear: clearSciAmJwt } = useSciAmJwtSync();
  const returnTo = pageData?.props?.returnTo;

  // Users who click the "Log out" button will be logged out before they reach this page
  // Users who navigate here might require calling the Auth0 logout function
  const [waitForAuth0, setWaitForAuth0] = useState(true);
  const allowRedirection = !waitForAuth0;
  useEffect(() => {
    if (isLoggedIn) {
      setWaitForAuth0(true);
      logout(returnTo?.path || "/");
    } else if (!isLoading) {
      setWaitForAuth0(false);
    }
  }, [isLoggedIn, isLoading, auth?.provider]);

  useEffect(() => {
    // Clear the user's SciAm JWT
    clearSciAmJwt();

    // Purge Piano-related cookies
    // @TODO: Remove some time after piano is fully dead
    clearPianoSession();
  }, []);

  // Redirect back to the site
  // Auth0 should already be doing this, but this is a fallback
  useEffect(() => {
    // Wait for client-side logout actions to complete before redirecting
    if (waitForAuth0) return;
    // Return to either a value derived from the returnTo query param or go back to the homepage
    window.location.href = returnTo?.path || "/";
  }, [waitForAuth0]);

  return (
    <BaseContent className={styles.logout}>
      <Loader />
      <h1>{allowRedirection ? "Signed Out" : "Signing Out"}</h1>
      {allowRedirection ? (
        <p>
          If you are not redirected automatically, <a href={returnTo?.path || "/"}>click here</a>.
        </p>
      ) : (
        <p>You are being signed out...</p>
      )}
    </BaseContent>
  );
}

function Callback() {
  const query = usePageQuery();
  const { pageData } = usePage();
  const { returnTo } = useAuth0CallbackParams();
  const { error, errorDescription } = pageData?.props || {};
  const authError = error || query.error;
  const redirectUrl = returnTo || pageData?.returnTo?.path || "/";
  const redirect = () => {
    window.location.href = redirectUrl;
  };

  // Redirect back to the site after 20s max.
  useEffect(() => {
    const timeout = setTimeout(() => redirect, 20 * 1000);
    return () => clearTimeout(timeout);
  }, []);

  const { isLoading, isLoggedIn, user } = useAuth();
  const { ready, payload, updating, update } = useSciAmJwtSync();

  // Determine the current state of the SciAm JWT request
  const idle = ready && !isLoading && !updating;
  const hasSciAmJwt = !!payload;
  useEffect(() => {
    // Don't do anything until the SciAm JWT request resolves.
    if (!idle) return;
    // If we have a SciAm JWT or the user is logged out, it's safe to redirect
    if (hasSciAmJwt || !isLoggedIn) {
      const redirectTimeout = setTimeout(redirect, 1000);
      return () => clearTimeout(redirectTimeout);
    }

    // If something goes wrong requesting a SciAm JWT, retry after a short cool-down period
    const updateCooldown = setTimeout(update, 1000);
    return () => clearTimeout(updateCooldown);
  }, [idle, hasSciAmJwt, isLoggedIn]);

  // Capture JWT errors in Sentry
  const hasSciAmJwtError = idle && isLoggedIn && !payload?.features;
  useEffect(() => {
    if (!hasSciAmJwtError) return;

    const error =
      payload?.features === null
        ? new Error("[Auth Callback] SciAm JWT missing user entitlements")
        : new Error("[Auth Callback] Failed to fetch SciAm JWT");

    window.__SENTRY__?.hub?.captureException?.(error, { extra: { uid: user?.uid } });
  }, [hasSciAmJwtError]);

  return (
    <BaseContent className={styles.callback}>
      {authError ? (
        <>
          <h1>{AUTH0_ERROR_STATES[authError] || "Problem Signing In"}</h1>
          <hr />
          {errorDescription || query.error_description ? (
            <>
              <p>{errorDescription || query.error_description}</p>
              <p>
                Please return to the <a href="/">homepage</a>.
              </p>
            </>
          ) : (
            <>
              <p>There was a problem signing you in.</p>
              <p>
                Please return to the <a href="/">homepage</a>.
              </p>
            </>
          )}
        </>
      ) : (
        <>
          <Loader />
          <h1>{AUTH0_ERROR_STATES.success}</h1>
          <p>You will be redirected back to the site shortly.</p>
          {isLoading || !ready ? (
            <p>Please wait...</p>
          ) : (
            <p>
              <a href={redirectUrl}>Click here if you are not redirected automatically.</a>
            </p>
          )}
        </>
      )}
    </BaseContent>
  );
}

function clearPianoSession() {
  const PIANO_COOKIES = [
    "xbc", // anonymous experience cookie
    "__tbc", // anonymous browser cookie
    "__utp", // user token cookie
    "__pid", // user data cookie
    "__idr", // user data cookie

    // Possibly not required?
    "__pil",
    "__eea",
    "__code",
    "piano-id-initial-gm-sso-shown-AID",
    "pnid-tc-string",
  ];
  const PIANO_COOKIE_MATCH = [/^p_.+/, /.+__ut$/, /.+__idr$/];

  const allCookies = Cookies.get();
  Object.keys(allCookies).forEach((cookie) => {
    if (
      PIANO_COOKIES.includes(cookie) ||
      PIANO_COOKIE_MATCH.some((regex) => regex.test?.(cookie))
    ) {
      Cookies.remove(cookie);
    }
  });

  // @TODO: Remove Piano SDK-based logouts after Chargebee launch
  window.tp?.pianoId?.logout();
}

export default AuthPageContent;
