import styles from "./Loader.module.css";
import SciAmMeatballIcon from "~/assets/sciam";

export function Loader() {
  return (
    <div className={styles.loader}>
      <SciAmMeatballIcon width="75px" height="75px" />
    </div>
  );
}

export default Loader;
